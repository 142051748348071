@import "assets/colors";
@import "assets/vars";

app-root {
  display: flex;
  height: 100%;
  width: 100%;
  position: fixed;
  z-index: 10;
}

.main-content {
  overflow-y: auto;
  padding: 10px;
  border-radius: 0px;
  width: 100%;
  margin-top: var(--header-size);
}

.ant-tabs-card.ant-tabs-top > .ant-tabs-nav .ant-tabs-tab-active,
.ant-tabs-card.ant-tabs-top > div > .ant-tabs-nav .ant-tabs-tab-active {
  border-bottom-color: transparent;
}

.ant-tabs-nav-more {
  color: white !important;
}

.ant-layout-header {
  line-height: normal;
  height: 70px;
}

.ant-tabs-tab-remove {
  color: white !important;

  &:hover {
    color: #AB9282 !important;
  }
}

.ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before, .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before {
  border: none !important;
}

.ant-tree .ant-tree-switcher {
  line-height: 18px;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before, .ant-spin-dot-item {
  background-color: transparent !important;
}

.ativo, .desativado, .nao-ativado {
  text-transform: capitalize;
  color: white;

  &:hover {
    color: white;
    border-color: transparent;
  }
}

.ativo {
  background-color: $success;

  &:hover, &:active, &:focus {
    background-color: rgba($success, 0.9);
    color: white;
  }
}

.desativado {
  background-color: $danger;

  &:hover, &:active, &:focus {
    background-color: rgba($danger, 0.9);
    color: white;
  }
}

.nao-ativado {
  color: rgba(0, 0, 0, .25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;

  &:hover, &:active, &:focus {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0.1);
  }
}

.custom-radio-group {
  .ant-radio-button-wrapper-checked:not(.nao-ativado) {
    color: white;
  }

  .ativo {
    background-color: $success;

    &:hover, &:active, &:focus {
      background-color: rgba($success, 0.9);
      color: white;
    }
  }

  .desativado {
    background-color: $danger;

    &:hover, &:active, &:focus {
      background-color: rgba($danger, 0.9);
      color: white;
    }
  }

  .nao-ativado {
    color: rgba(0, 0, 0, .25);
    background-color: #f5f5f5;
    border-color: #d9d9d9;

    &:hover, &:active, &:focus {
      color: rgba(0, 0, 0, .25);
      background-color: #f5f5f5;
      border-color: #d9d9d9;
    }

    &:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.centralized-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}


.loading-icon {
  font-size: 68px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

nz-breadcrumb {

  margin-bottom: 16px;
}

.ant-card {
  box-shadow: 0 2px 18px 1px rgb(49 53 72 / 10%);
  border-radius: 0.45rem;
  border: 1px solid rgba(0, 0, 0, 0.06);
  margin-bottom: 1.875rem;
}

.ant-card-table .ant-card-body {
  padding: 0 !important;
}

.fonte-doze tr {
  font-size: 12px;
}

.table-small {

  td.ant-table-cell, th.ant-table-cell {
    padding: 8px !important;
  }

  .ant-table-pagination {
    margin-right: 15px;
  }

  .ant-pagination-total-text {
    display: block;
    position: absolute;
    left: 15px;
  }

  .ant-table-column-sorters {
    padding: 0 !important;
  }

}

.align-items-center {
  align-items: center !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.d-flex {
  display: flex !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.ant-card.compress {
  margin-bottom: 0;

  .ant-card-head {
    border-radius: 0.45rem !important;
  }

  .ant-card-body {
    opacity: 0 !important;
    height: 0 !important;
    padding: 0 !important;
  }

}

.card-fullscreen {
  display: block !important;
  z-index: 9999 !important;
  position: fixed !important;
  width: 99% !important;
  height: 99% !important;
  top: 5px !important;
  right: 5px !important;
  left: 5px !important;
  bottom: 10px !important;
  overflow: auto !important;
}

.ant-table-row-expand-icon {
  margin-right: 0 !important;
}
